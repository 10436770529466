import { useContext } from "react";
import { PlotContext, SearchService } from "./plot";
import { Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import BasicMenu from "./menu";

function MobileTable({ data }) {

    if (data[0] === "firstLoad") {
        return (
            <div className="m-5">
                Enter service to fetch data
            </div>
        )
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <p className="text-lg">Provider</p>
                        </TableCell>
                        <TableCell>
                            <p className="text-lg">Price</p>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((ele) => {
                            const keys = Object.keys(ele);
                            return (
                                <TableRow key={ele[keys[0]]}>
                                    <TableCell>
                                        {ele[keys[0]]}
                                    </TableCell>
                                    <TableCell>
                                        {parseFloat(ele[keys[1]]).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function ShowLoading() {
    return (
        <Box className="h-80 w-80 flex">
            <CircularProgress className="m-auto" />
        </Box>
    )
}

export default function MobilePlot() {

    const { updateService, fetchPlotData, setNegotiatedPrices, deletePlot, data, negotiatedPrices, isLoading, gotoThird, isFirstLoad, npiDetails } = useContext(PlotContext);

    return (
        <Box className="w-80 flex flex-col">
            <div className="flex">
                <SearchService updateService={updateService} fetchPlotData={fetchPlotData} setNegotiatedPrices={setNegotiatedPrices} npiDetails={npiDetails} />
                <IconButton size="small" onClick={() => deletePlot(data.id)}>
                    <Delete />
                </IconButton>
            </div>
            {
                isLoading ?
                <ShowLoading />:
                <div>
                    <MobileTable data={negotiatedPrices} />
                </div>
            }
            {
                isFirstLoad ?
                    <div></div> :
                    <Box className="mx-auto p-2">
                        <BasicMenu providers={negotiatedPrices} gotoThird={gotoThird} />
                    </Box>
            }
        </Box>
    )
}