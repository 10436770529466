import { useNavigate } from "react-router-dom";
import {
    Paper,
    Autocomplete,
    TextField,
    Box,
    IconButton,
    useMediaQuery,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, createContext } from "react";
import axios from "axios";
import service_uris from "../utils/uri";
import DesktopPlot from "./desktopPlot";
import MobilePlot from "./mobilePlot";

// context for children
export const PlotContext = createContext("");

export function SearchService({ updateService, fetchPlotData, setNegotiatedPrices, npiDetails }) {
    const [services, setServices] = useState(["please enter billing code/service name"]);

    const sendRequest = async (event, value, reason) => {
        const text = value;
        if (reason === "clear") {
            setNegotiatedPrices(["firstLoad"])
            return
        }
        
        axios.get(
            service_uris["typesense"] + "/collections/services/documents/search",{
                params: {
                    "q": text,
                    "query_by": "service_name",
                    "filter_by": `providers:=${npiDetails["npi"]}`,
                    "exclude_fields": "providers"
                },
                headers: {
                    "X-TYPESENSE-API-KEY": `${process.env.REACT_APP_TYPESENSE_API_KEY}`,
                }
            }
        ).then((response) => {
            const data = response.data;
            setServices(data.hits.map((item) => item.document.service_name));
            if (data.found === 0) {
                setServices(["No results found"]);
            }
            if (event.type === "click") {
                const code = data.hits[0].document.billing_code;
                updateService(event, data.hits[0].document.service_name, code);
            }
        }).catch((err) => {
            setServices(["Error fetching services"]);
        });
        
    };
    return (
        <Box className="flex flex-start p-2 flex-1">
            <Autocomplete
                disablePortal
                id="searchInput"
                name="service"
                onInputChange={(event, value) => sendRequest(event, value)}
                onChange={(event, value, reason) => {
                    sendRequest(event, value, reason);
                }}
                options={services}
                loading={true}
                filterOptions={(x) => x}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Service"
                        size="small"
                        sx={{flexGrow: 1}}
                    />
                )}
                className="grow max-w-96"
            />
            <IconButton size="small" onClick={fetchPlotData}>
                <SearchIcon fontSize="inherit" />
            </IconButton>
        </Box>
    );
}

export function RenderTable({ negotiatedPrices }) {
    const prices = negotiatedPrices.map((ele) => parseInt(ele.averagePrice));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    let average = 0;
    // calc average
    prices.forEach(num => { average += num });
    average = parseInt(average / prices.length);

    const data = {
        "Maximum": isNaN(maxPrice) ? 0 : maxPrice,
        "Minimum": isNaN(minPrice) ? 0 : minPrice,
        "Average": isNaN(average) ? 0 : average
    }


    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <b>Regional Summary</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data).map((key) => {
                        return (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>${data[key]}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function PlotComponent({
    deletePlot,
    data,
    updatePlots,
    npiDetails,
    setAlertMessage,
    setShowAlert
}) {
    const navigation = useNavigate();
    const [pltData, setPltData] = useState(data);
    const [isLoading, setIsLoading] = useState(false);
    const [negotiatedPrices, setNegotiatedPrices] = useState(["firstLoad"]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    // media query
    const isMobile = useMediaQuery("(max-width:600px)");

    const dummyData = [
        { _id: "UHC", averagePrice: 500 },
        { _id: "Aetna", averagePrice: 450 },
        { _id: "Kaiser", averagePrice: 380 },
    ]

    useEffect(() => {

    }, [pltData, negotiatedPrices])
    // setPrices(dummy_data);
    const fetchPlotData = () => {
        setNegotiatedPrices(dummyData);
        setIsLoading(true);
        axios
            .get(service_uris["backend"] + "/ServicesAndPayers", {
                params: {
                    npi: npiDetails.npi,
                    billingCode: pltData.billingCode,
                },
            })
            .then((res) => {
                setNegotiatedPrices(res.data.negotiated_prices);
                setIsLoading(false);
                setIsFirstLoad(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setAlertMessage("Error fetching payer data: " + err.message);
                setShowAlert(true);
                console.log(err);
            });
    };

    const updateService = (event, value, code) => {
        var newData = pltData;
        newData.service = value;
        newData.billingCode = code;
        setPltData(newData);
        updatePlots(pltData.id, pltData);
    };


    // const handleClick = () => {

    // }

    const gotoThird = (provider) => {
        // we only need provider
        navigation("/compare", {
            state: {
                "longitude": npiDetails["longitude"],
                "latitude": npiDetails["latitude"],
                "billingCode": pltData["billingCode"],
                // removing the newline char added to the provider string on WebKit based browsers
                "payerName": provider.trim(),
                "service": pltData["service"],
                "dba": npiDetails["dba"],
                "zipCode": npiDetails["zipCode"]
            }
        });

    }


    return (
        <PlotContext.Provider
            value={{ updateService, fetchPlotData, setNegotiatedPrices, deletePlot, data, negotiatedPrices, pltData, isLoading, gotoThird, isFirstLoad, npiDetails }}
        >
            <Paper>
                {
                    isMobile?
                    <MobilePlot />:
                    <DesktopPlot />
                }
            </Paper>
        </PlotContext.Provider>
    );
}
