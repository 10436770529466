import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { useNavigation } from 'react-router-dom';

export default function BasicMenu({ providers, gotoThird }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // const navigation = useNavigation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        const payerName = event.target.innerText
        setAnchorEl(null);
        if (payerName === "Menu" || payerName === ""){
            return
        }
        gotoThird(event.target.innerText);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant='contained'
            >
                Compare Nearby
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {providers.map((vals) => {
                    return <MenuItem key={vals._id} onClick={handleClose}>{vals._id}</MenuItem>
                })}
            </Menu>
        </div>
    );
}
