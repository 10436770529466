import SearchNpi from "./pages/search.jsx";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./pages/errorPage.jsx";
import SecondPage from "./pages/compareServices.jsx";
import { useState } from "react";
import { ErrorContext } from "./utils/errorContext.jsx";
import CompareNPI from "./pages/compareNPI.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <SearchNpi />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services",
        element: <SecondPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/compare",
        element: <CompareNPI />,
        errorElement: <ErrorPage />,
    },
]);

// temporary
function InBetaRibbon() {
    return (
	<div class="absolute transform rotate-45 bg-green-600 text-center text-white font-semibold py-1 right-[-35px] top-[32px] w-[170px]">
		In Beta
	</div>
    )
}


export default function App() {

    const [errorMsg, setErrorMsg] = useState("");
    const [showError, setShowError] = useState(false);

    return (
        <ErrorContext.Provider value={{errorMsg, setErrorMsg, showError, setShowError}}>
        <InBetaRibbon />
	<RouterProvider router={router} />
        </ErrorContext.Provider>
    )
};
