import { Box, Paper } from "@mui/material";

export default function NPICard({ npiData }) {
    return (
        <Paper className="flex flex-col md:flex-row justify-between p-5 items-center font-sans">
            <Box className="flex flex-col grow-0 md:w-1/2">
                <div className="text-wrap font-medium">
                    {npiData["DBA"]}
                </div>
                <div className="text-wrap text-xs">
                    {npiData["FULL_ADDRESS_PRACTICE"]}
                </div>
                <div>
                    Distance: <p className="font-medium inline">{parseFloat(npiData["distance"] * 0.621371 * 0.01).toFixed(2)} miles</p>
                </div>
            </Box>
            <Box className="flex flex-col md:w-1/3 md:text-lg">
                <p className="font-semibold">
                    $ {npiData["negotiated_price"]}
                </p>
                <p className="text-gray-500 text-sm">
                    Negotiated Price
                </p>
            </Box>
        </Paper>
    )
}
