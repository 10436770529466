import { Box, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import BasicMenu from "./menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { PlotContext, RenderTable, SearchService } from "./plot";
import { BarChart } from "@mui/x-charts";
import { useContext } from "react";

function DrawPlot({ pltData }) {

    if (pltData[0] === "firstLoad") {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width={800} height={500} >
                <Typography>
                    Enter service to fetch data.
                </Typography>
            </Box>
        )
    }

    const valueFormatter = (value) => `$${parseInt(value)}`;
    return (
        <BarChart
            dataset={pltData}
            xAxis={[{
                scaleType: "band",
                dataKey: "_id",
                categoryGapRatio: 0.6
            }]}
            series={[{ dataKey: "averagePrice", valueFormatter }]}
            width={700}
            height={400}

        />
    );
}


function NoDataAvailable({ pltData }) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" width={800} height={500} sx={{ backgroundColor: "gray" }}>
            <Typography>
                No Data Found for service <strong>{pltData.service}</strong> for the current NPI
            </Typography>
        </Box>
    )
}

export default function DesktopPlot() {

    const { updateService, fetchPlotData, setNegotiatedPrices, deletePlot, data, negotiatedPrices, pltData, isLoading, gotoThird, npiDetails } = useContext(PlotContext);

    return (
        <Box className="">
            <Box display="flex">
                <SearchService updateService={updateService} fetchPlotData={fetchPlotData} setNegotiatedPrices={setNegotiatedPrices} npiDetails={npiDetails} />
                <Box className="ml-auto">
                    <IconButton
                        size="small"
                        onClick={() => {
                            deletePlot(data.id);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
            {
                negotiatedPrices.length === 0 ?
                    <NoDataAvailable pltData={pltData} negotiatedPrices={negotiatedPrices} /> :
                    <Box className="flex p-2 space-x-2">
                        <Box>{
                            isLoading ?
                                <Skeleton variant="rounded" width={700} height={400} /> :
                                <DrawPlot pltData={negotiatedPrices} className="w-max" />
                        }</Box>
                        <Divider flexItem orientation="vertical" variant="middle" />
                        <Box display="flex" flexDirection="column" gap={1} alignItems={"center"}>
                            {
                                isLoading ?
                                    <Skeleton variant="rounded" width={200} height={400} /> :
                                    <RenderTable negotiatedPrices={negotiatedPrices} />
                            }
                            <Box className="mt-auto">
                                <BasicMenu providers={negotiatedPrices} gotoThird={gotoThird} />
                            </Box>
                        </Box>
                    </Box>
            }
        </Box>
    )
}