import React, { useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import '../index.css';


const formatDesc = (dba, npi, price) => {
    return `<p>${dba}</p><p className="font-medium">NPI: ${npi}</p><strong className="text-lg">Price: $${price}</strong>`;
}

const createSource = (data) => {
    let source = {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': [],
        }
    }
    let feature = {
        'type': 'Feature',
        'properties': {
            'description': ''
        },
        'geometry': {
            'type': 'Point',
            'coordinates': [-77.038659, 38.931567]
        }
    }
    let featureList = data.map((element) => {
        let tempFeature = JSON.parse(JSON.stringify(feature));
        tempFeature.properties.description = formatDesc(element["DBA"], element["facilityNPI"], element["negotiated_price"]);
        tempFeature.geometry.coordinates = [element["longitude"], element["latitude"]];
        tempFeature.properties.price = parseFloat(element["negotiated_price"]);
        return tempFeature;
    })
    source.data.features = featureList;

    return source;
}

const addMarkers = (map, data, minPrice, maxPrice) => {
    map.on('load', async () => {
        const image = await map.loadImage('https://maplibre.org/maplibre-gl-js/docs/assets/custom_marker.png');
        // const image = await map.loadImage('https://fontawesome.com/icons/location-dot?f=classic&s=solid');
        map.addImage('custom-marker', image.data, {sdf: true});

        const sourceData = createSource(data);
        map.addSource('places', sourceData);

        // Add a layer showing the places.
        map.addLayer({
            'id': 'places',
            'type': 'symbol',
            'source': 'places',
            'layout': {
                'icon-image': 'square',
                'icon-overlap': 'cooperative',
                'icon-size': 2,
                'text-field': 
                ["number-format",  
                    ["get", "price"], 
                    { "currency": "USD", "min-fraction-digits": 0, "max-fraction-digits": 2 }
                ],
                'icon-text-fit': "both",
                'icon-text-fit-padding': [2, 2, 2, 2]
            },
            'paint': {
                // 'icon-color': 'blue',
                'icon-opacity': 0.8,
                'icon-color': ["interpolate-hcl",
                ["linear"],
                ["get", "price"],
                minPrice, "green",
                maxPrice, "red",
                ],
                // 'icon-halo-color': ['to-color', 'white'],
                // 'icon-halo-width': 2,
                
            }
        });

        // Create a popup, but don't add it to the map yet.
        const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        map.on('mouseenter', 'places', (e) => {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', 'places', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });
    });
}

export const MapWithMarkers = ({ data, apiKey }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    
    if (apiKey.length === 0){
        console.error("Need an API key to render");
    }

    useEffect(() => {
        if (map.current) return; // stops map from intializing more than once

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${apiKey}`,
            center: [data[0]["longitude"], data[0]["latitude"]],
            zoom: 11
        });

        const prices = data.map((ele) => parseInt(ele["negotiated_price"]));
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        addMarkers(map.current, data, minPrice, maxPrice);

    }, [apiKey, data]);


    return (
        <div style={{}}>
            <div ref={mapContainer} style={{ width: "100%", height: "100%", position: "absolute" }} id="map"/>
        </div>
    );
}