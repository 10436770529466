import { Box, CircularProgress, Backdrop } from '@mui/material';
import { MapWithMarkers } from '../components/NPIMap';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorContext } from '../utils/errorContext';
import service_uris from '../utils/uri';
import NPICard from '../components/NPICard';

function NPIList({ npisData }) {

    return (
        <Box className="p-5 flex flex-col space-y-2">
            {npisData.map((ele) => {
                return <NPICard npiData={ele} />
            })}
        </Box>
    )
}

function SortBar({ sortByPrice, sortByDistance }) {
    return (
        <Box className="bg-slate-600 flex flex-row justify-end space-x-3 p-1">
            <p className="text-white mr-auto text-sm md:text-base">
                Sort by
            </p>
            <button className="bg-slate-600 text-slate-400 text-sm md:text-base" onClick={() => sortByPrice("ascending")}>
                Price Low to High
            </button>
            <button className="bg-slate-600 text-slate-400 text-sm md:text-base" onClick={() => sortByPrice("descending")}>
                Price High to Low
            </button>
            <button className="bg-slate-600 text-slate-400 text-sm md:text-base" onClick={() => sortByDistance()}>
                Distance
            </button>
        </Box>
    )
}

export default function CompareNPI({ data }) {
    const [npisData, setNpisData] = useState([
        {
            "id": 1,
            "DBA": "MASSACHUSETTS URGENT CARE PROVIDER NETWORK, LLC",
            "facilityNPI": 198478392,
            "longitude": -77.038659,
            "latitude": 38.931567,
            "negotiated_price": 700,
            "distance": 30,
            "FULL_ADDRESS_PRACTICE": "7332 E BUTHERUS DR,SCOTTSDALE,AZ,85260"
        },
    ])

    const [isLoading, setIsLoading] = useState(true);
    const errorContext = useContext(ErrorContext);
    const navigate = useNavigate();
    const prevData= useLocation();
    
    useEffect(() => {
        axios.get(
            service_uris["backend"] + "/nearbyFacilities", {
            params: {
                "long": prevData.state["longitude"],
                "lat":prevData.state["latitude"],
                "payerName": prevData.state["payerName"],
                "billingCode": prevData.state["billingCode"],
            }
        }
        )
            .then((res) => {
                if (res.data.length > 0){
                    setNpisData(res.data)
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                    errorContext.setErrorMsg("Error fetching NPI data: no results");
                    errorContext.setShowError(true);
                    navigate("/services", { state: { "dba": prevData.state["dba"], "zipCode": prevData.state["zipCode"] } });
                }
                
            })
            .catch((err) => {
                setIsLoading(false);
                errorContext.setErrorMsg("Error fetching NPI data: " + err.message);
                errorContext.setShowError(true);
                navigate("/services", { state: { "dba": prevData.state["dba"], "zipCode": prevData.state["zipCode"] } });
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

    const sortByPrice = (order) => {
        let sorted = []
        if (order === "ascending"){
            sorted = [].concat(npisData)
            .sort((a, b) => parseFloat(a["negotiated_price"]) > parseFloat(b["negotiated_price"]) ? 1 : -1)
        }else{
            sorted = [].concat(npisData)
            .sort((a, b) => parseFloat(a["negotiated_price"]) < parseFloat(b["negotiated_price"]) ? 1 : -1)
        }
        setNpisData(sorted)
    }

    const sortByDistance = () => {
        setNpisData([].concat(npisData)
        .sort((a, b) => parseFloat(a["distance"]) > parseFloat(b["distance"]) ? 1 : -1))
    }

    return (
        <Box className="flex flex-col flex-wrap md:flex-row h-screen">
            <Box className="h-1/2 md:basis-1/3 md:h-full flex flex-col">
                <Box className="p-2 md:pl-5 md:pt-5">
                    <p className='md:text-xl'>
                        Service: {prevData.state["service"]}
                    </p>
                    <p className='md:text-xl'>
                        Payer: {prevData.state["payerName"]}
                    </p>
                </Box>
                <SortBar sortByPrice={sortByPrice} sortByDistance={sortByDistance}/>
                <Box className="overflow-auto">
                    <NPIList npisData={npisData} />
                </Box>
            </Box>
            <Box className="h-1/2 md:h-full md:basis-2/3 relative">
                <MapWithMarkers data={npisData} apiKey={`${process.env.REACT_APP_MAPTILER_API_KEY}`} />
            </Box>
        </Box>
    )
}