import PlotComponent from "../components/plot";
import { IconButton, Stack, Box, Backdrop, CircularProgress, Button, Snackbar, Alert, Paper } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ErrorContext } from "../utils/errorContext";
import service_uris from "../utils/uri";

function processData(resData) {
    const data = {
        "npi": resData[0]["facilityNPI"],
        "address": resData[0]["FULL_ADDRESS_PRACTICE"],
        "dba": resData[0]["DBA"],
        "zipCode": resData[0]["zipCode"],
        "latitude": resData[0]["latitude"],
        "longitude": resData[0]["longitude"],
    }
    return data;
}

function NpiDetails({ dba, zipCode, setNpiDetails, npiDetails, setAlertMessage, setShowAlert }) {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const errorContext = useContext(ErrorContext);
    useEffect(() => {
        axios.get(
            service_uris["backend"] + "/npi", {
            params: {
                "dba": dba,
                "zipCode": zipCode
            }
        }
        )
            .then((res) => {
                if (res.data.length > 0){
                    setNpiDetails(processData(res.data))
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                    errorContext.setErrorMsg("Error fetching NPI data: no results");
                    errorContext.setShowError(true);
                    navigate("/");
                }
            })
            .catch((err) => {
                setIsLoading(false);
                errorContext.setErrorMsg("Error fetching NPI data: " + err.message);
                errorContext.setShowError(true);
                navigate("/");
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

    return (
        <Paper className="flex flex-row flex-wrap justify-between p-5 items-center font-sans md:space-between" elevation={0}>
            <Box className="flex flex-col">
                <div className="text-wrap text-2xl md:w-2/3">
                    {npiDetails["dba"]}
                </div>
                <div className="text-wrap md:text-lg">
                    {npiDetails["address"]}
                </div>
            </Box>
            <Box className="font-semibold md:text-2xl">
                NPI: {npiDetails["npi"]}
            </Box>
        </Paper>
    )
}


export default function SecondPage() {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [pltArr, setPltArr] = useState([]);
    const data = useLocation();
    const [npiDetails, setNpiDetails] = useState({
        "npi": "",
        "dba": "",
        "address": "",
        "location": ""
    });

    var pltTemplate = {
        "id": "",
        "service": "",
        "stats": {
            "Average": "499",
            "Minimum": "0",
            "Maximum": "0",
        },
        "isLoading": false,
        "negotiated_prices": [
            { payer: "UHC", price: 500 },
            { payer: "Etna", price: 450 },
            { payer: "Kaiser", price: 380 },
            { payer: "Test", price: 490 },
            { payer: "Test2", price: 440 },
        ],
    }

    const addPlot = () => {
        pltTemplate.id = crypto.randomUUID();
        pltTemplate.stats.Average = Math.floor(Math.random() * 500);
        setPltArr([...pltArr, pltTemplate]);
    }

    const deletePlot = (index) => {
        setPltArr(pltArr.filter((item) => item.id !== index));
    }

    const updatePlots = (id, data) => {
        setPltArr(pltArr.map((item) => {
            if (item.id === id) {
                return data;
            }
            return item;
        }))
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway"){
            return;
        }
        setShowAlert(false);
    }


    return (
        <Box display="flex" alignItems="center" flexDirection="column">
            <NpiDetails
                dba={data.state["dba"]}
                zipCode={data.state["zipCode"]}
                setNpiDetails={setNpiDetails}
                npiDetails={npiDetails}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert}
            />
            <Stack alignItems="center" spacing={0.5}>
                {pltArr.map((item) => {
                    return (
                        <PlotComponent
                            deletePlot={deletePlot}
                            data={item}
                            key={item.id}
                            updatePlots={updatePlots}
                            npiDetails={npiDetails}
                            setAlertMessage={setAlertMessage}
                            setShowAlert={setShowAlert}
                        />
                    )
                })}
                <IconButton size="large" onClick={addPlot}>
                    <Button variant="contained">Add Service</Button>
                </IconButton>
            </Stack>
            <Snackbar open={showAlert} autoHideDuration={2000} onClose={handleAlertClose}>
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                    onClose={handleAlertClose}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

